import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VehicleService } from 'src/app/services/vehicle.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';
import { LivetrackService } from 'src/app/services/livetrack.service';
import { state, style, trigger } from "@angular/animations";
import { UserSessionService } from 'src/app/services/usersession.service';




@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  animations: [
    trigger("rotatedState", [
      state("default", style({ transform: "rotate(0)" })),
      state("90", style({ transform: "rotate(-90deg)" })),
      state("180", style({ transform: "rotate(-180deg)" })),
      state("270", style({ transform: "rotate(-270deg)" })),
    ]),
    trigger("rotatedState1", [
      state("default", style({ transform: "rotate(0)" })),
      state("90", style({ transform: "rotate(-90deg)" })),
      state("180", style({ transform: "rotate(-180deg)" })),
      state("270", style({ transform: "rotate(-270deg)" })),
    ]),
    trigger("rotatedState2", [
      state("default", style({ transform: "rotate(0)" })),
      state("90", style({ transform: "rotate(-90deg)" })),
      state("180", style({ transform: "rotate(-180deg)" })),
      state("270", style({ transform: "rotate(-270deg)" })),
    ]),
  ],
})
export class EditComponent implements OnInit {
  form: FormGroup;
  districtList: any[];
  transportList: any[];
  dataValue: any;
  status: boolean = false;
  assemblyList: any[];
  registerNo: any;
  deviceCode: any;
  regNo: any;
  vehicleId: any;
  districtCode: number;
  assemblyCode: number;
  zpvList: any[];
  canEditOfficerInfo:boolean;
  env = environment;
  stateName: string;
  getStateName: string;
  isshow =true;
  state: string = "default";
  state1: string = "default";
  roleId: any;
  roleType: any;

  constructor(private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private vehicleService: VehicleService,
    private userSessionService: UserSessionService,
    private alertService: AlertService,
    private livetrackService: LivetrackService,
    private dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.zpvList = [];
    this.roleId = this.userSessionService.roleId();
    this.roleType = RoleType.Root;
    debugger

    }

  ngOnInit() {
    this.vehicleId = this.data.vehicleId;
    this.initializeValidators();
    this.getDitricts();
    this.getById();
    this.getconficStateName()
  }

  rotate() {
    debugger;
    this.state =
      this.state === "default"
        ? "90"
        : this.state === "90"
        ? "180"
        : this.state === "180"
        ? "270"
        : "default";
  }

  rotate1() {
    debugger;
    this.state1 =
      this.state1 === "default"
        ? "90"
        : this.state1 === "90"
        ? "180"
        : this.state1 === "180"
        ? "270"
        : "default";
  }
  getconficStateName(){
    this.dashboardService.getconfig("StateName", true).subscribe((result) => {
      if (result) {
        this.getStateName = result.value
    this.form.controls['getStateName'].setValue(this.getStateName);
      }
    });    
  }

  initializeValidators() {
    this.form = this.formBuilder.group({
      regNo: [''],
      regNo1: [''],
      regNo2: [''],
      regNo3: [''],
      regNo4: [''],
      isShift1: [''],
      isShift2: [''],
      isShift3: [''],
      sequenceNumber: [''],
      officerName1: [''],
      officerName2: [''],
      officerName3: [''],
      officerMobileNo1: [''],
      officerMobileNo2: [''],
      officerMobileNo3: [''],
      driverName1: [''],
      driverName2: [''],
      driverName3: [''],
      driverMobileNo1: [''],
      driverMobileNo2: [''],
      driverMobileNo3: [''],
      district_Id: [''],
      assemblyConstitution_Id: [''],
      transportCategoryId: [''],
      transportvalue: [''],
      zpv:[[null]],
      vehicleCode:[null],
      getStateName:['']
    });
  }

  getDitricts() {
    this.dashboardService.getDistrictBasedOnState(1, true).subscribe(result => {
      if (result) {
        this.districtList = result.filter((element) => element.key != 0);
      }
    });
    this.vehicleService.getTransportCategory(true).subscribe(result => {
      if (result) {
        this.transportList = result;
      }
    })
  }

  getById() {
    this.vehicleService.getAssemblyInfo(this.vehicleId, true).subscribe((res) => {
      if (res) {
        this.status = true;
        this.dataValue = res;
        this.registerNo = res.regNo;
        this.deviceCode = res.vehicleName;
        this.canEditOfficerInfo = res.canEditOfficerInfo;    
        if(this.dataValue.latitude > 0 && this.dataValue.longitude > 0){
          this.livetrackService.getAddress(this.dataValue.latitude,this.dataValue.longitude).subscribe((result) => {
            this.dataValue.address = result[0].display_name
          });    
        }
        this.form.patchValue(res);
        this.getZPV(res.assemblyConstitution_Id);
      
      }
    });
  }
  getZPV(id:number){
    const postData = {
      AssemblyId:id,
      ZpvVehicleId: this.vehicleId
    }
    this.vehicleService.getZpv(postData, true).subscribe((res) => {
      if (res) {
      this.zpvList = [];
      this.zpvList = res;
      console.log(this.zpvList);
      if(this.dataValue.zpvEvmQueryViewModel !== null && this.dataValue.zpvEvmQueryViewModel.evmVehicleIds?.length > 0){
        this.form.controls['zpv'].setValue(this.dataValue.zpvEvmQueryViewModel.evmVehicleIds);
      }
      }
    });
  }
  onDistrictChange(e) {
    var event = e[0].data.key;
    this.vehicleService.getAssembly(event, true).subscribe(result => {
      if (result) {
        this.assemblyList = result.filter((element) => element.key != 0);
      }
    });
    let eventval = e[0].data.value;
    let splitVal = eventval.split('-')
    this.districtCode = splitVal[0];
    this.formVehicleCode();
  }

  onAssemblyChange(e) {
    let eventval = e[0].data.value;
    let splitVal = eventval.split('-')
    this.assemblyCode = splitVal[0];
    this.formVehicleCode();
  }

  onTransportChange(e) {
    var event = e[0].data.value;
    this.form.controls['transportvalue'].setValue(event);
    this.formVehicleCode();
  }

  vehicleKeyUp(e) {
    this.formVehicleCode();
  }

  formVehicleCode() {
    this.deviceCode = '';

    // if (this.districtCode)
    //   this.deviceCode += this.districtCode.toString().trim();
    // if (this.assemblyCode)
    //   this.deviceCode += this.assemblyCode.toString().trim();
    // if (this.form.value.transportvalue)
    //   this.deviceCode += this.form.value.transportvalue.toString().trim();
    if (this.form.value.sequenceNumber)
      this.deviceCode += this.form.value.sequenceNumber.toString().trim();

    this.form.controls['vehicleCode'].setValue(this.deviceCode);
  }


  registerKeyUp(e) {
    const value = this.form.value.getStateName.toUpperCase();
    this.form.controls['getStateName'].setValue(value);

    //  this.registerNo = 'TN' + this.form.value.regNo1 + this.form.value.regNo2 + this.form.value.regNo3;
    if (this.form.value.getStateName||this.form.value.regNo1 || this.form.value.regNo2 || this.form.value.regNo3) {
      this.registerNo = this.form.value.getStateName + this.form.value.regNo1 + this.form.value.regNo2 + this.form.value.regNo3;
    } else {
      this.registerNo = this.dataValue.regNo;
    }

    this.form.controls['regNo'].setValue(this.registerNo);
  }


  onSubmit() {

    if (this.form.value.assemblyConstitution_Id) {
      if (this.form.value.regNo1 != "" && this.form.value.regNo2 != "" && this.form.value.regNo3 != "") {
        if (this.form.value.regNo1 != "" || this.form.value.regNo2 != "" || this.form.value.regNo2 != "") {
          this.regNo = this.registerNo;
        }
        else {
          this.regNo = this.dataValue.regNo;
        }
        var savedata = {
          "state_Id": 1,
          "stateName": this.dataValue.stateName,
          "district_Id": this.form.value.district_Id,
          "districtName": this.dataValue.districtName,
          "assemblyConstitution_Id": this.form.value.assemblyConstitution_Id,
          "acName": this.dataValue.acName,
          "vehicleId": this.dataValue.vehicleId,
          "transportCategoryId": this.form.value.transportCategoryId,
          "regNo": this.form.value.regNo,
          "vehicleName": this.deviceCode,
          "officerName1": this.form.value.officerName1,
          "officerName2": this.form.value.officerName2,
          "officerName3": this.form.value.officerName3,
          "officerMobileNo1": this.form.value.officerMobileNo1,
          "officerMobileNo2": this.form.value.officerMobileNo2,
          "officerMobileNo3": this.form.value.officerMobileNo3,
          "driverName1": this.form.value.driverName1,
          "driverName2": this.form.value.driverName2,
          "driverName3": this.form.value.driverName3,
          "driverMobileNo1": this.form.value.driverMobileNo1,
          "driverMobileNo2": this.form.value.driverMobileNo2,
          "driverMobileNo3": this.form.value.driverMobileNo3,
          "isShift1": this.form.value.isShift1,
          "isShift2": this.form.value.isShift2,
          "isShift3": this.form.value.isShift3,
          "shift1UserId": null,
          "shift2UserId": null,
          "shift3UserId": null,
          "sequenceNumber": this.form.value.sequenceNumber,
          "zpvEvmQueryViewModel":(this.form.value.zpv.length > 0 && this.form.value.zpv[0] !== null) ? {
            zpvVehicleId:this.vehicleId,
            evmVehicleIds:this.form.value.zpv
          } : null
        }
        console.log('savedata', savedata)
        this.vehicleService.saveTransportDetails(savedata).subscribe((res) => {
          if (res.isSuccess) {
            this.alertService.success('Successfully Submitted');
            this.dialogRef.close();
          }
          else {
            this.alertService.error(res.htmlFormattedFailures);
          }
        });
      } else if (this.form.value.regNo1 == "" && this.form.value.regNo2 == "" && this.form.value.regNo3 == "") {
        if (this.form.value.regNo1 != "" || this.form.value.regNo2 != "" || this.form.value.regNo2 != "") {
          this.regNo = this.registerNo;
        }
        else {
          this.regNo = this.dataValue.regNo;
        }
        var savedata = {
          "state_Id": 1,
          "stateName": this.dataValue.stateName,
          "district_Id": this.form.value.district_Id,
          "districtName": this.dataValue.districtName,
          "assemblyConstitution_Id": this.form.value.assemblyConstitution_Id,
          "acName": this.dataValue.acName,
          "vehicleId": this.dataValue.vehicleId,
          "transportCategoryId": this.form.value.transportCategoryId,
          "regNo": this.form.value.regNo,
          "vehicleName": this.deviceCode,
          "officerName1": this.form.value.officerName1,
          "officerName2": this.form.value.officerName2,
          "officerName3": this.form.value.officerName3,
          "officerMobileNo1": this.form.value.officerMobileNo1,
          "officerMobileNo2": this.form.value.officerMobileNo2,
          "officerMobileNo3": this.form.value.officerMobileNo3,
          "driverName1": this.form.value.driverName1,
          "driverName2": this.form.value.driverName2,
          "driverName3": this.form.value.driverName3,
          "driverMobileNo1": this.form.value.driverMobileNo1,
          "driverMobileNo2": this.form.value.driverMobileNo2,
          "driverMobileNo3": this.form.value.driverMobileNo3,
          "isShift1": this.form.value.isShift1,
          "isShift2": this.form.value.isShift2,
          "isShift3": this.form.value.isShift3,
          "shift1UserId": null,
          "shift2UserId": null,
          "shift3UserId": null,
          "sequenceNumber": this.form.value.sequenceNumber,
          "zpvEvmQueryViewModel":(this.form.value.zpv.length > 0 && this.form.value.zpv[0] !== null) ? {
            zpvVehicleId:this.vehicleId,
            evmVehicleIds:this.form.value.zpv
          } : null
        }
        console.log('savedata', savedata)
        this.vehicleService.saveTransportDetails(savedata).subscribe((res) => {
          if (res.isSuccess) {
            this.alertService.success('Successfully Submitted');
            this.dialogRef.close();
          }
          else {
            this.alertService.error(res.htmlFormattedFailures);
          }
        });
      }
      else {
        this.alertService.error("Please fill Register No field");
      }

    } else {
      this.form.controls["assemblyConstitution_Id"].setValidators(Validators.required);
      this.form.controls["assemblyConstitution_Id"].updateValueAndValidity();
      // this.form.controls["userPhotoPath"].clearValidators();
      //this.form.controls["userPhotoPath"].updateValueAndValidity();
    }

  }

  openmapview(longitude, latitude) {
    debugger;
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, "_blank");
  }

  validateFormControl() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      }
    })
  }

  onCancel() {
    this.dialogRef.close();
  }

}
