// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiBaseUrl: 'https://testapi.falconevt.com',
  // apiBaseUrl: 'http://devapi.falconevt.com',
  signalrUrl: 'https://signalr.falconevt.com/notifyHub',
  // apiBaseUrl: 'http://localhost:5005',
  // signalrUrl: 'https://localhost:9012/notifyHub',
  tokenEndPoint: '/api/token',
  StoppageIntervalSecs: 300,
  dataInterval: 10000,
  production: false,
  environment: 'Local',
  showEnvironment: true,
  PoliceTransportCategoryId: 2,
  Livetrackingcol:'col-md-6',
  PoliceRoleIds: [22],
  stateName: 'MP',
  loginImageUrl: 'assets/img/ico/falcon.png',
  sideBarImageUrl: 'assets/img/logos/falconlogo.png',
  evtTitle: 'Falcon EVT',
  loginTitle: 'GPS TRACKING SYSTEM FOR ELECTION DEPARTMENT',
  theme: 'ThemeTwo',
  signallost: true,
};
