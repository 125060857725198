import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { UserSessionService } from './usersession.service';
import { AuthenticationService } from './authentication.service';
import { AlertService } from './alert.service';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()

export class HttpInterceptorService implements HttpInterceptor {

    private baseUrl = environment.apiBaseUrl;

    constructor(
        private router: Router,
        private sessionService: UserSessionService,
        private authService: AuthenticationService,
        private alertService: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const started = Date.now();

        // add authorization header with jwt token if available
        const authToken = this.sessionService.authToken();

        const isTokenEndPoint = request.url.match('/api/token');
        if (isTokenEndPoint === null && this.sessionService.userId() && authToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
        }

        return next.handle(request).pipe(tap((event: any) => {
            if (event instanceof HttpResponse) {
                const action = request.urlWithParams.replace(this.baseUrl, '');
                const elapsed = Date.now() - started;
                // console.log(`${action} took ${elapsed} milliseconds`);
            }
        }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 401) {
                    this.authService.logOut();
                    this.router.navigate(['/login']);
                } else {
                    this.broadcastFriendlyErrorMessage(error);
                }
            }
            // return the error to the method that called it
            return throwError(error);
        })) as any;
    }

    broadcastFriendlyErrorMessage(rejection) {
        let msg = '';
        if (rejection.status === 0 && (rejection.statusText === '' || rejection.statusText === 'Unknown Error')) {
            this.alertService.error('Unable to connect to the server, please try again in a couple of seconds.');
        } else if (rejection.status === 400) {
            if (rejection.error) {// jshint ignore:line
                msg = (rejection.error.message) ? rejection.error.message : rejection.error; // jshint ignore:line
            }
            this.alertService.error(msg);
        } else if (rejection.status === 404) {
            if (rejection.message) {
                this.alertService.error(rejection.message);
            }
        } else if (rejection.status === 500) {
            if (rejection.error && rejection.error.Message) {
                this.alertService.error(rejection.error.Message);
            } else if (rejection.message) {
                const message = rejection.message;
                // while (ex.innerException) {
                //   ex = ex.innerException;
                // }
                this.alertService.error(message);
            }
        } else if (rejection.status === 409) {
            if (rejection.error && rejection.error.Message) {
                this.alertService.error(rejection.error.Message);
            } else if (rejection.message) {
                const message = rejection.message;
                this.alertService.error(message);
            }
        } else if (rejection.responseStatus === 401) {
            this.authService.logOut();
            this.router.navigate(['/login']);
        } else if (rejection.responseStatus === 0) {
            this.alertService.error('Error occured, while uploading file');
        } else if (rejection.responseStatus === 400) {
            if (rejection.response) { // jshint ignore:line
                msg = rejection.response; // jshint ignore:line
            }
            this.alertService.error(msg);
        }
    }

    // broadcastFriendlyErrorMessage(rejection) {
    //     let msg = '';
    //     if (rejection.status === 0 && (rejection.statusText === '' || rejection.statusText === 'Unknown Error')) {
    //         this.alertService.error('Unable to connect to the server, please try again in a couple of seconds.');
    //     } else if (rejection.status === 400) {
    //         if (rejection.error) {// jshint ignore:line
    //             msg = (rejection.error.message) ? rejection.error.message : rejection.error; // jshint ignore:line
    //         }
    //         this.alertService.error(msg);
    //     } else if (rejection.status === 404) {
    //         if (rejection.message) {
    //             this.alertService.error(rejection.message);
    //         }
    //     } else if (rejection.status === 500) {
    //       if(rejection.error && rejection.error.Message) {
    //         this.alertService.error(rejection.error.Message);
    //       } else if (rejection.message) {
    //             const message = rejection.message;
    //             // while (ex.innerException) {
    //             //   ex = ex.innerException;
    //             // }
    //             this.alertService.error(message);
    //         }
    //     } else if (rejection.responseStatus === 401) {
    //         this.authService.logOut();
    //         this.router.navigate(['/login']);
    //     } else if (rejection.responseStatus === 0) {
    //         this.alertService.error('Error occured, while uploading file');
    //     } else if (rejection.responseStatus === 400) {
    //         if (rejection.response) { // jshint ignore:line
    //             msg = rejection.response; // jshint ignore:line
    //         }
    //         this.alertService.error(msg);
    //     }
    // }
}
