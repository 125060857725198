import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { environment } from 'src/environments/environment';
//import { SwUpdate } from '@angular/service-worker';

// @HostListener("window:onbeforeunload", ["$event"])
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'AED';
  constructor(dateTimeAdapter: DateTimeAdapter<any>,
    private router: Router
    //, private swUpdate: SwUpdate
  ) {
    dateTimeAdapter.setLocale('en-in');
  }

  ngOnInit(): void {
    // this.swUpdate.available.subscribe(event => {
    //   console.log('A newer version is now available. Refresh the page now to update the cache');
    // });
    // this.swUpdate.checkForUpdate()

    if (environment.production) {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/tripreport' || e.url === '/tripsimulationreport' || e.url.startsWith('/tripsimulationreport')
          || e.url.startsWith('/serviceschedules') || e.url.startsWith('/targets') || e.url.startsWith('/tariffs')
          || e.url.startsWith('/regions') || e.url.startsWith('/regions') || e.url.startsWith('/divisions')
          || e.url.startsWith('/subdivisions') || e.url.startsWith('/consumables') || e.url.startsWith('/workshops')
          || e.url.startsWith('/providers')
          || e.url.startsWith('/users/')
          || e.url.startsWith('/vehiclelogreport')
          || e.url.startsWith('/devices/')
          || e.url.startsWith('/vehicles/')
          || e.url.startsWith('/implements/')
          || e.url.startsWith('/drivers/')
          || e.url.startsWith('/fuellog/')
          || e.url.startsWith('/tripentries/')
          || e.url.startsWith('/servicelog/')
          || e.url.startsWith('/consumableentries/')
          || e.url.startsWith('/expenseentries/')) {
          document.body.classList.add('removeglobalfilter');
        } else {
          document.body.classList.remove('removeglobalfilter');
        }

        //document.body.classList.remove('showfullmap');
        ///document.body.classList.remove('showhalfmap');
      }
    });
  }

  clearAllData() {
    // this.authService.logOut();
  }

}
