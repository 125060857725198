import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { UserSession } from '../models/usersession';

@Injectable()

export class UserSessionService {

  session = new UserSession();
  localStorageSessionKey: string;

  constructor() {
    this.localStorageSessionKey = 'eGramam-' + environment.apiBaseUrl + '-AuthData';
  }

  create(session) {// jshint ignore:line
    this.setLocalStorageProperties(session);
  }

  destroy() {// jshint ignore:line
    this.setLocalStorageProperties(new UserSession());
    localStorage.removeItem('role');
    localStorage.clear();
  }

  load() { // jshint ignore:line
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData;
  }

  authToken() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).authToken;
  }

  userId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).userId;
  }

  getUserName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).userFullName;
  }

  roleId(): number {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? 0 : +JSON.parse(jsonData).roleId;
  }

  roleName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData).roleName;
  }

  districtId(): number[] {
    let districtIds = [];
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    if (jsonData && jsonData !== null) {
      const districts = JSON.parse(jsonData).districtId;
      districts.forEach(element => {
        if (element && element !== '')
          districtIds.push(parseInt(element));
      });
    }
    return districtIds;
  }

  assemblyId(): number[] {
    let assemblyIds = [];
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    if (jsonData && jsonData !== null) {
      const assemblys = JSON.parse(jsonData).assemblyId;
      assemblys.forEach(element => {
        if (element && element !== '')
          assemblyIds.push(parseInt(element));
      });
    }
    return assemblyIds;
  }

  getFilterregionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterregionId;
  }
  getFilterDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterdivisionId;
  }

  getFilterSubDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filtersubdivisionId;
  }

  getFilterregionName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterregionName;
  }

  getFilterDivisionName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filterdivisionName;
  }
  getFilterSubDivisionName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).filtersubdivisionName;
  }

  // getRegionId() {
  //   const jsonData = localStorage.getItem(this.localStorageSessionKey);
  //   return jsonData == null ? [] : JSON.parse(jsonData).regionId;
  // }

  // getDivisionId() {
  //   const jsonData = localStorage.getItem(this.localStorageSessionKey);
  //   return jsonData == null ? [] : JSON.parse(jsonData).divisionId;
  // }

  // getSubDivisionId() {
  //   const jsonData = localStorage.getItem(this.localStorageSessionKey);
  //   return jsonData == null ? [] : JSON.parse(jsonData).subDivisionId;
  // }

  getCurrentSubDivisionId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentSubDivisionId;
  }

  getClientId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).clientId;
  }

  getCurrentClientId() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentClientId;
  }

  getCurrentClientName() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).currentClientName;
  }

  getEmail() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).email;
  }

  getLanguageType() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? [] : JSON.parse(jsonData).languageType;
  }

  referrence2() {
    const jsonData = JSON.parse(localStorage.getItem(this.localStorageSessionKey));
    return JSON.parse(jsonData.departmentId).Services;
  }

  setLocalStorageProperties(session: any) {// jshint ignore:line
    localStorage.setItem(this.localStorageSessionKey, JSON.stringify(session));
  }

  getLocalStorageWithKey(key: any) {// jshint ignore:line
    return localStorage.getItem(key);
  }

  setLocalStorageWithKey(key: any, session: any) {// jshint ignore:line
    localStorage.setItem(key, JSON.stringify(session));
  }

  isDynamicPassword() {
    const jsonData = localStorage.getItem(this.localStorageSessionKey);
    return jsonData == null ? '' : JSON.parse(jsonData);
  }

}

