import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { timeout } from 'rxjs/operators';

@Injectable()
export class LivetrackService {

    constructor(private dataService: DataService) {
    };

    get(refresh: Boolean) {
        return this.dataService.getData('/api/vehiclestatus', refresh);
    }

    // getByVehicleId(vehicleId: number) {
    //     return this.dataService.getRecord('/api/vehicle/mapvehicles?Vehicle_Id='+vehicleId+'&State_Id=0&District_Id=0&AssemblyConstitution_Id=0');
    // }

    getByVehicleId(data: any, pageType: number) {
        // return this.dataService.getRecordWithParams('/api/vehicle/mapvehicles', data);
        if (pageType === 1) {
            return this.dataService.getRecordWithParams('/api/vehicle/mapvehicles', data);
        } else {
            return this.dataService.getRecordWithParams('/api/vehicle/evmmapvehicles', data);
        }
    }

    getLiveStreamById(vehicleId: number) {
        const url = 'https://buzecamapi.cloudvms.in/dmcamlist/?CameraID=' + vehicleId;
        return this.dataService.getExternalData(url);
    }

    getVehicleDetailById(vehicleId: number) {
        return this.dataService.getRecord('/api/vehicle/details/' + vehicleId);
    }
    
    getPlaceOfIneterestFence(data:any) {
        return this.dataService.post('/api/fence/fencelocation', data);
    }

    getSstLocationsByVehicleId(vehicleIds: any) {
        return this.dataService.getRecordWithParams('/api/vehicle/sstlocationbyvehicle', vehicleIds);
    }

    getSstLocationsById(id: number) {
        return this.dataService.getRecord('/api/vehicle/sstlocationbyid/' + id);
    }

    getSstLocationsByVehicleIds(vehicleIds: any) {
        return this.dataService.getRecordWithParams('/api/vehicle/sstlocation', vehicleIds);
    }

    getCcLocationsByAssembly(data: any) {
        return this.dataService.getRecordWithParams('/api/vehicle/cclocations', data);
    }

    getCcLocationsByVehicleId(vehicleId: any) {
        return this.dataService.getData('/api/cclocation/ccl/' + vehicleId, true);
    }

    getWarehouseLocationsByAssembly(data: any) {
        return this.dataService.getRecordWithParams('/api/vehicle/warehouselocations', data);
    }

    postSstLocation(data: any) {
        return this.dataService.post('/api/vehicle/updatesstlocation', data);
    }

    getDashboardData(regionId: number, divisionId: number, subdivisionId: number) {
        return this.dataService.getRecord('/api/vehiclestatus/dashboard/' + regionId + '/' + divisionId + '/' + subdivisionId);
    }

    getClientList() {
        return this.dataService.getRecord('/api/client/lookupbyuser');
    }

    getAddress(lat, lng) {
        const url = 'https://nominatim.openstreetmap.org/search?format=json&q=' + lat + ',' + lng;
        return this.dataService.getExternalData(url);
    }
    getvehicleAddress(lat, lng) {
        const url = 'https://nominatim.openstreetmap.org/search?format=json&q=' + lat + ',' + lng;
        return this.dataService.getExternalData(url).pipe(timeout(4000));
    }

    // /api/vehiclestatus/parseddetail

    getparsedetail(data: any){
        return this.dataService.post('/api/vehiclestatus/parseddetail', data)
    }
}
