export class UserSession {
    userId: number;
    userFullName: string;
    roleId: number;
    authToken: string;
    roleName: string;
    email: string;
    mobileNumber: string;
    languageType: number;
    clientId: any = [];
    currentClientId: any;
    currentClientName: any;
    isDynamicPassword: boolean;
    role: any = [];
    districtId: number[];
    assemblyId: number[];
    // divisionId:number;
    filterdivisionId: number;
    filterregionId: number
    filtersubdivisionId: number;
    filterregionName: any;
    filterdivisionName: any;
    filtersubdivisionName: any;
}
