import { NgModule } from '@angular/core';

@NgModule({
    exports: [


    ],
    imports: [
    ],
    providers: [
        
    ],
    declarations: [

    ]
})
export class SharedModule { }
