import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({providedIn: 'root'})
export class DashboardService {

    getEventRoute = '/api/newevents/';
    evmDashboardFilters: {districtId: any, assemblyConstitutionId: any, transportCategoryId: any};
    dashboardType: number;
    dashboardFilters: {districtId: any, assemblyConstitutionId: any, transportCategoryId: any};

    constructor(private dataService: DataService) {
    };

    save(data: any) {
        return this.dataService.post('/api/newevents', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    saveNew(data: any) {
        return this.dataService.post('/api/schedule', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getActualTarget(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/tripentry/targetactual/' + data.region + '/' + data.division + '/' + data.subdivision, refresh);
    }

    getSummaryDetails(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/vehicle/dashboardsummary', refresh);
    }

    revenueData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/tripentry/revenue/' + data.region + '/' + data.division + '/' + data.subdivision + '/' + data.revenueFilterType, refresh);
    }
    // =============================================================Real Time Tracking Api================================//
    // state
    getState(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/district/statelookup', refresh);
    }


    //District
    getDistrictBasedOnState(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/district/lookup/' + data, refresh);
    }
    getdevicestatus(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/deviceApprovalStatusType', refresh);
    }
    getdispatchCode(district:any,refresh: boolean){
        
        return this.dataService.getData('/api/vehicle/dispatchedlookup/'+district, refresh);
    }

    //Assembly
    getAssemblyBasedOnDistrict(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/assembly/lookup/' + data, refresh);
    }

    //Shifts

    getShifts(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/shifts', refresh);
    }


    //transportID
    getTransportID(refresh: boolean) {
        return this.dataService.getData('/api/vehicle/transportcategory', refresh);
    }



    //Vehicle Tracking
    getDashboardDataLive(data) {
        return this.dataService.getDataWithParams('/api/vehicle/livelist', data, true);
    }
    getDBTDataLive(districtId:any,dispatchCode:any,refresh: boolean) {
    return this.dataService.getData('/api/vehicle/dispatchedLiveList/'+districtId +'/'+dispatchCode, refresh);
    }

    getNotification() {
        return this.dataService.getData('/api/vehicle/notifications', true);
    }

    getSeizureData() {
        // return Observable.of(	
        //     {
        //       "todaySeizedCount": 0,
        //       "todaySeizedValue": 0,
        //       "totalSeizedCount": 0,
        //       "totalSeizedValue": 0,
        //       "mapData": [
        //         {
        //           "latitude": 0,
        //           "longitude": 0,
        //           "address": "address",
        //           "mobileNumber": "9999999999",
        //           "seizedValue": 100000,
        //           "quantity": 5,
        //           "seizedItemTypeName": "itemTypeName",
        //           "unitOfMeasureName": "unitOfMeasureName"
        //         }
        //       ]
        //     })
        return this.dataService.getData('/api/seizeditem/MapData', true)
    }

    setDashboardFilters(filters) {
        this.dashboardType = 1
        this.dashboardFilters = filters
    }

    setEvmDashboardFilters(filters) {
        this.dashboardType = 2
        this.evmDashboardFilters = filters
    }
    savefence(data: any) {
        return this.dataService.post('/api/fence', data).map(response => {
            return response;
        });
    }
    getplaceofinterest(refresh: boolean) {
        return this.dataService.getData('/api/lookup/placeOfInterestType', refresh);
    }
    getfencelist(parliamentConstitutionId:any,assemblyConstitutionId:any,refresh: boolean) {
        return this.dataService.getData('/api/fence/'+parliamentConstitutionId  + '/' + assemblyConstitutionId, refresh);
    }
    getFencingDatabyid(id: any, refresh: boolean) {
        return this.dataService.getData('/api/fence/' + id, refresh);
    }

    geofenceDelete(id:any,refresh){
        return this.dataService.post('/api/fence/delete/'+id, refresh)
    }
    getconfig(key: any, refresh: boolean) {
    return this.dataService.getData('/api/config/info/'+key, refresh);
    }
    
}
