import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class SubDivisionService {

    getEventRoute = '/api/subdivision/';

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/subdivision/lookup', refresh);
    }
    getLookUpbyId(refresh: boolean, id:number) {
        return this.dataService.getData('/api/subdivision/lookup/'+id, refresh);
    }
    get(refresh: boolean) {
        return this.dataService.getData('/api/subdivision', refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/subdivision/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/subdivision/', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number) {
        return this.dataService.delete('/api/subdivision/' + id).map(response => {
            this.dataService.clearCache();
            this.dataService.clearRouteCache(this.getEventRoute);
           
            return response;
        });
    }

    // delete(id: number) {
    //     return this.dataService.delete('/api/division/' + id).map(response => {
    //         this.dataService.clearRouteCache(this.getEventRoute);
    //         return response;
    //     });
    // }


}
