import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class UserService {

    getEventRoute = '/api/user/';

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/user/lookup', refresh);
    }

    // get(subdivisionId: number, refresh: boolean) {
    //     return this.dataService.getData('/api/user/bysubdivision/' + subdivisionId, refresh);
    // }
    get(data:any, refresh: boolean) {
        return this.dataService.getData('/api/user/'+ data.region + '/' +data.division+'/'+data.subdivision, refresh);
    }
    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/user/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/user', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number, status:number) {
        return this.dataService.post('/api/user/update/' + id+ '/'+status, null).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    forgotpasswordUser(user: any) {
        return this.dataService.post('/api/user/forgotpassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    changepasswordUser(user: any) {
        return this.dataService.post('/api/user/changepassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }


    newpaswordUser(user: any) {
        return this.dataService.post('/api/user/newpasword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }
    getRoll(refresh: boolean) {
        return this.dataService.getData('/api/user/role', refresh);
    }
    getUserImg(userid: any, refresh: any) {
        return this.dataService.getData('/api/user/' + userid, refresh);
    }
    getMenu(refresh: boolean) {
        return this.dataService.getData('/api/user/menu', refresh);
    }

}
