import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/services/usersession.service';
import swal from 'sweetalert2';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DivisionService } from 'src/app/services/division.service';
import { SubDivisionService } from 'src/app/services/subdivision.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LogComponent } from '../../pages/master/logs/log.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  form: FormGroup;
  username: '';
  clientList: any[];
  client = [];
  languageType: any;
  Languages: any[];
  dropdownconfig = {};
  user_url: string;
  userdata: any;
  userImg: any;
  userId: number;
  emailId: any;
  regionList: any[];
  divisionList: any[];
  subdivisionList: any[];
  regionId: any;
  divisionId: any;
  subdivisionId: any;
  filterregionList: any[];
  filterdivisionList: any[];
  filtersubdivision: any[];
  notificationList: any[];
  roleName: any;
  roleId: number;
  accessall: boolean;
  active: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private userSessionService: UserSessionService,
    private navigationService: NavigationService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private divisionService: DivisionService,
    private subdivisionService: SubDivisionService,
    private vehicleService: VehicleService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|ta|tl|de|af/) ? browserLang : 'en');
    this.languageType = userSessionService.getLanguageType();
    this.userId = userSessionService.userId();
    this.emailId = userSessionService.getEmail();
    this.regionList = [];
    this.divisionList = [];
    this.subdivisionList = [];
    this.filterregionList = [];
    this.filterdivisionList = [];
    this.filtersubdivision = [];
    this.notificationList = [];
    this.roleId = this.userSessionService.roleId();
    // this.accessall = this.roleId === RoleType.Root || this.roleId === RoleType.SuperAdmin || this.roleId === RoleType.ChiefEngineer
    //   || this.roleId === RoleType.SeniorEngineer;
  }

  ngOnInit(): void {
    this.username = this.userSessionService.getUserName();
    this.roleName = this.userSessionService.roleName();
    this.dropdownconfig = {
      search: true,
      placeholder: 'Select Client',
      height: '250px',
    }
    this.getNotificationBink();
    this.initializeValidators();
    this.getLanguage();
    this.updateRegion(0);
    this.updateDivision(0);
    this.updateSubDivision(0);
    this.getNotification();
  }

  getNotificationBink() {
    this.vehicleService.getNotificationBlink(true).subscribe(result => {
      if (result) {
        this.active = result;
        console.log('this.active', this.active);
      }
    });
  }

  refresh() {
    this.getLookupDivision(0);
    this.form.controls['regionid'].setValue(0);
    this.form.controls['divisionId'].setValue(0);
    this.form.controls['subdivisionId'].setValue(0);
    this.updateRegion(0);
    this.updateDivision(0);
    this.updateSubDivision(0);
  }
  initializeValidators() {
    this.form = this.formBuilder.group({
      regionid: [0, [Validators.required]],
      divisionId: [0, [Validators.required]],
      subdivisionId: [0, [Validators.required]],
    });
  }

  regionChange(event) {
    this.getLookupDivision(this.form.value.regionid);
    this.form.controls['divisionId'].setValue(0);
    this.form.controls['subdivisionId'].setValue(0);
    this.updateRegion(this.form.value.regionid);
    this.updateDivision(0);
    this.updateSubDivision(0);
  }

  updateParam() {
    const urls = this.router.url.split('?');
    const url2 = this.router.url.split('/');
    if (url2.length === 2) {
      this.router.navigate([urls[0]], {
        queryParams: { data: new Date().getTime() }
      });
    }
  }

  getLookupDivision(id) {
    this.divisionService.getLookUpbyId(true, id).subscribe(result => {
      if (result) {
        this.divisionList = [];
        this.filterdivisionList = [];
        this.divisionList = result;
        this.filterdivisionList = this.divisionList.slice();
        const divisionLength = this.divisionList.length;
        if (divisionLength === 1) {
          this.form.controls['divisionId'].setValue(this.divisionList[0].key);
          this.getLookupSubDivision(this.form.value.divisionId);
          this.updateDivision(this.form.value.divisionId);
          this.updateSubDivision(0);
        } else {
          this.updateParam();
        }
      } else {
        this.updateParam();
      }
    })
  }

  divisionChange() {
    this.getLookupSubDivision(this.form.value.divisionId);
    this.form.controls['subdivisionId'].setValue(0);
    this.updateDivision(this.form.value.divisionId);
    this.updateSubDivision(0);
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: { data: new Date().getTime() }
    });
  }

  getLookupSubDivision(id) {
    this.subdivisionService.getLookUpbyId(true, id).subscribe(result => {
      if (result) {
        this.subdivisionList = [];
        this.filtersubdivision = [];
        this.subdivisionList = result;
        this.filtersubdivision = this.subdivisionList.slice();
        const subdivisionLength = this.subdivisionList.length;
        if (subdivisionLength === 1) {
          this.form.controls['subdivisionId'].setValue(this.subdivisionList[0].key);
          this.updateSubDivision(this.form.value.subdivisionId);
        }
        this.updateParam();
      }
    })
  }

  subdivisionChange() {
    localStorage.setItem('subdivisionid', this.form.value.subdivisionId);
    this.updateSubDivision(this.form.value.subdivisionId);
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: { data: new Date().getTime() }
    });
  }

  updateRegion(regionId) {
    const obj = JSON.parse(this.userSessionService.load());
    obj.filterregionId = regionId;

    const regiondata = this.regionList.filter(e => {
      return e.key === regionId
    })
    obj.filterregionName = regiondata;
    this.userSessionService.create(obj);
  }

  updateDivision(divisionId) {
    const obj = JSON.parse(this.userSessionService.load());
    obj.filterdivisionId = divisionId;
    const divisiondata = this.divisionList.filter(e => {
      return e.key === divisionId
    })
    obj.filterdivisionName = divisiondata;
    this.userSessionService.create(obj);
  }

  updateSubDivision(subDivisionId) {
    const obj = JSON.parse(this.userSessionService.load());
    obj.filtersubdivisionId = subDivisionId;
    this.userSessionService.create(obj);

    const Subdivisiondata = this.subdivisionList.filter(e => {
      return e.key === subDivisionId
    })
    obj.filtersubdivisionName = Subdivisiondata;
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();

    const title = this.translate.instant('LogoutConfirmation');
    const txt = this.translate.instant('Youwanttologout');
    const Yes = this.translate.instant('Yes');
    const No = this.translate.instant('No');
    swal.fire({
      title,
      text: txt,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: Yes,
      cancelButtonText: No,
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem('isLoggedin');

        if (!localStorage.getItem('isLoggedin')) {
          this.router.navigate(['/auth/login']);
        }
      }
    })
  }

  getLanguage() {
  }

  changeClient(item) {
    if (item.value) {
      this.updateClientName(item.value.description);
      this.updateClientId(item.value.id);
      this.clientList = _.orderBy(this.clientList, ['description'], ['asc']);
      if (this.router.url === '/dashboard') {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['dashboard']);
      }
    }
  }

  initializeDefaultClient(item) {
    this.updateClientId(item.id);
    this.updateClientName(item.description);
  }

  updateClientId(clientId) {
    const obj = JSON.parse(this.userSessionService.load());
    obj.currentClientId = clientId;
    this.userSessionService.create(obj);
  }

  updateClientName(clientName) {
    const obj = JSON.parse(this.userSessionService.load());
    obj.currentClientName = clientName;
    this.userSessionService.create(obj);
  }

  openMyProfile() {
    const myuserId = this.userSessionService.userId();
    this.user_url = '/user/' + myuserId;
    // Second param is actionInfo given as 0 to open user profile in Edit mode (not in view mode)
    this.navigationService.goToProfile(myuserId, 0);
  }

  getNotification() {
    this.dashboardService.getNotification().subscribe(result => {
      if (result) {
        this.notificationList = result;
      }
    })
  }

  openNotificationsDialog(e) {
    const dialogRef = this.dialog.open(LogComponent, {
      disableClose: true,
      width: '70vw',
      maxWidth: '70vw',
      height: '50vw',
      data: {
        disable: true,
        vehicleId: e.vehicleId,
        eventType: e.eventType
      }
    })
    dialogRef.afterClosed().subscribe(data => {
      if (data) { }
    });
  }

  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangepasswordComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: 'mat-dialog'

    })
    dialogRef.afterClosed().subscribe(data => {
      if (data) { }
    });

  }
}
