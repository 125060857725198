import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeahterIconModule } from 'src/app/core/feather-icon/feather-icon.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LivetrackService } from 'src/app/services/livetrack.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
// import { ImageViewerModule } from 'ng2-image-viewer';
import { DirectivesModule } from 'src/app/views/layout/directives/directives.module';
import { RegionService } from 'src/app/services/region.service';
import { EditComponent } from './edit.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxImageZoomModule } from 'ngx-image-zoom';

const routes: Routes = [
    {
        path: '',
        component: EditComponent
    }
]

@NgModule({
    declarations: [EditComponent],
    providers: [
        RegionService,
        DashboardService,
        LivetrackService,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forChild(routes),
        FeahterIconModule,
        NgbDropdownModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatDialogModule,
        DirectivesModule,
        NgxSelectModule,
        NgxImageZoomModule,
    ]
})
export class EditModule { }
