import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AlertService } from 'src/app/services/alert.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  form: FormGroup;
  datas = [];
  //public data: any[];
  dataSource = new MatTableDataSource(this.datas);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  logdata: any;
  status: boolean = false;
  vehicleId: any;
  logList: any[];
  disable: any;
  constructor(private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<LogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.vehicleId = this.data.vehicleId;
    this.initializeValidators();
    this.getLogData();
    this.getLogId();
    if (this.data.disable) {
      this.form.controls['mobileNo'].clearValidators();
      this.form.controls['mobileNo'].updateValueAndValidity();
    } else {
      this.form.controls['mobileNo'].setValidators(Validators.required);
      this.form.controls['mobileNo'].updateValueAndValidity();
    }
  }

  initializeValidators() {
    this.form = this.formBuilder.group({
      name: [''],
      logTypeId: ['', Validators.required],
      description: [''],
      vehicleId: [''],
      additionalInfo: [''],
      comment: ['', Validators.required],
      mobileNo: [''],
      officerMobileNo1: [''],
      officerMobileNo2: [''],
      officerMobileNo3: [''],
      officerName1: [''],
      officerName2: [''],
      officerName3: [''],
      driverName1: [''],
      driverName2: [''],
      driverName3: [''],
      driverMobileNo1: [''],
      driverMobileNo2: [''],
      driverMobileNo3: [''],
    });
  }

  getLogData() {
    this.vehicleService.getLogData(this.vehicleId, 0, true).subscribe(result => {
      if (result) {
        this.status = true;
        this.logdata = result;
        this.form.patchValue(result);
      }
    })
  }

  getLogId() {
    this.vehicleService.getLogId(true).subscribe(result => {
      if (result) {
        this.logList = result;
        //this.logList = _.orderBy(this.logList, ['index'], ['asc']);
        setTimeout(() => {
          if (this.data.disable) {
            this.form.controls['logTypeId'].setValue(this.data.eventType);
            this.form.controls['logTypeId'].updateValueAndValidity();
            this.disable = this.data.disable;
          }
        }, 100);
      }
    });
  }

  onOfficerChange1(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.officerMobileNo1);
      this.form.controls['additionalInfo'].setValue(this.form.value.officerName1);
    }
  }

  onOfficerChange2(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.officerMobileNo2);
      this.form.controls['additionalInfo'].setValue(this.form.value.officerName2);
    }
  }

  onOfficerChange3(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.officerMobileNo3);
      this.form.controls['additionalInfo'].setValue(this.form.value.officerName3);
    }
  }

  onDriverChange1(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.driverMobileNo1);
      this.form.controls['name'].setValue(this.form.value.driverName1);
    }
  }

  onDriverChange2(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.driverMobileNo2);
      this.form.controls['name'].setValue(this.form.value.driverName2);
    }
  }

  onDriverChange3(event) {
    if (event.target.checked) {
      this.form.controls['mobileNo'].setValue(this.form.value.driverMobileNo3);
      this.form.controls['name'].setValue(this.form.value.driverName3);
    }
  }

  onLogChange(e) {
    var event = e[0].data.value;
    this.form.controls['description'].setValue(event);
  }

  onSubmit() {
    if (this.form.value.logTypeId == 0) {
      this.form.controls['logTypeId'].setValue(null);
    }
    if (this.form.valid) {
      this.vehicleService.saveLogDetails(this.form.value).subscribe((res) => {
        if (res.isSuccess) {
          this.alertService.success('Successfully Submitted');
          this.dialogRef.close();
        }
        else {
          this.alertService.error(res.htmlFormattedFailures);
        }
      });
    } else {
      this.validateFormControl();
    }
  }

  validateFormControl() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      }
    })
  }

  onCancel() {
    this.dialogRef.close();
  }

}