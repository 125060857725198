import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FileviewerComponent } from './fileviewer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LightboxModule } from 'ngx-lightbox';
import { ImageViewerModule } from '@emazv72/ngx-imageviewer';

const routes: Routes = [
    {
        path: '',
        component: FileviewerComponent,
        data: {
            title: 'File Viewer'
        }
    }
];

@NgModule({
    imports: [
        CommonModule,
        LightboxModule,
        MatDialogModule,
        ImageViewerModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        FileviewerComponent
    ],
    providers: [],
    exports: [RouterModule],
})
export class FileviewerModule { }
