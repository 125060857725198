import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { DashboardsummaryComponent } from './views/pages/dashboardsummary/dashboardsummary.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./views/pages/realtimetracking/realtimetracking.module').then(m => m.RealTimeTrackingModule)
      },
      {
        path: 'Dashboard',
        loadChildren: () => import('./views/pages/realtimetracking/realtimetracking.module').then(m => m.RealTimeTrackingModule)
      },
      {
        path: 'DashboardSummary',
        loadChildren: () => import('./views/pages/dashboardsummary/dashboardsummary.module').then(m => m.DashboardsummaryModule)
      },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      // },
      {
        path: 'dashboard/:id',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'DashBoardSeizure',
        loadChildren: () => import('./views/pages/dashboardseizure/dashboardseizure.module').then(m => m.DashboardseizureModule)
      },
      {
        path: 'EVMDashboard',
        loadChildren: () => import('./views/pages/evm-dashboard/evm-dashboard.module').then(m => m.EvmDashboardModule)
      },

      // {
      //   path: 'multiplevehicletrack',
      //   loadChildren: () => import('./views/pages/tracking/multipletracking/multipletracking.module').then(m => m.MultipletrackingModule)
      // },
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      //  { path: '', component: DashboardsummaryComponent },

      {
        path: 'users',
        loadChildren: () => import('./views/pages/master/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('./views/pages/master/device/device.module').then(m => m.DeviceModule)
      },
      {
        path: 'providers',
        loadChildren: () => import('./views/pages/master/provider/provider.module').then(m => m.ProviderModule)
      },
      {
        path: 'drivers',
        loadChildren: () => import('./views/pages/master/driver/driver.module').then(m => m.DriverModule)
      },
      {
        path: 'livetrack',
        loadChildren: () => import('./views/pages/tracking/livetrack/livetrack.module').then(m => m.LivetrackModule)
      },
      {
        path: 'livetrack/:id',
        loadChildren: () => import('./views/pages/tracking/livetrack/livetrack.module').then(m => m.LivetrackModule)
      },
      {
        path: 'vehiclegroups',
        loadChildren: () => import('./views/pages/master/vehiclegroup/vehiclegroup.module').then(m => m.VehiclegroupModule)
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./views/pages/master/vehicle/vehicle.module').then(m => m.VehicleModule)
      },
      {
        path: 'fuellog',
        loadChildren: () => import('./views/pages/rollcall/fuellog/fuellog.module').then(m => m.FuelLogModule)
      },

      {
        path: 'profile/:id/:actionInfo',
        loadChildren: () => import('./views/pages/profile/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'evmteam',
        loadChildren: () => import('./views/pages/teams/teams.module').then(m => m.TeamsModule)
      },
      {
        path: 'countingcentre',
        loadChildren: () => import('./views/pages/countingcentre/countingcentre.module').then(m => m.CountingcentreModule)
      },
      {
        path: 'fuelreport',
        loadChildren: () => import('./views/pages/reports/fuelreport/fuelreport..module').then(m => m.FuelreportModule)
      },
      {
        path: '#/TripReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: 'stoppagetruckreport',
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./views/pages/reports/stoppagetruckreport/stoppagetruckreport.module').then(m => m.StoppagetruckreportModule)
      },
      {
        path: 'tripsimulationreport',
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./views/pages/reports/tripsimulationreport/tripsimulationreport.module').then(m => m.TripsimulationModule)
      },
      {
        path: 'deviceapproval',
        loadChildren: () => import('./views/pages/reports/tripsimulationreport/tripsimulationreport.module').then(m => m.TripsimulationModule)
      },
      
      // {
      //   path: 'regions',
      //   loadChildren: () => import('./views/pages/master/region/region.module').then(m=> m.RegionModule)
      // },
      {
        path: 'EvmVehicleMapping',
        loadChildren: () => import('./views/pages/master/evmvehiclemapping/evmvehiclemapping.module').then(m => m.EvmvehiclemappingModule)
      },
      {
        path: 'divisions',
        loadChildren: () => import('./views/pages/master/division/division.module').then(m => m.DivisionModule)
      },
      {
        path: 'subdivisions',
        loadChildren: () => import('./views/pages/master/subdivision/subdivision.module').then(m => m.SubDivisionModule)
      },
      {
        path: 'makes',
        loadChildren: () => import('./views/pages/master/make/make.module').then(m => m.MakeModule)
      },
      {
        path: 'models',
        loadChildren: () => import('./views/pages/master/model/model.module').then(m => m.ModelModule)
      },
      {
        path: 'log',
        loadChildren: () => import('./views/pages/master/logs/log.module').then(m => m.LogModule)
      },
      {
        path: 'mapdebug',
        loadChildren: () => import('./views/pages/reports/mapdebug/mapdebug.module').then(m => m.MapdebugModule)
      },
      {
        path: 'report/1',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: 'report/2',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: 'report/8',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: 'report/9',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: 'report/11',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DistanceTraveledReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/NotificationReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/LogReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/VehicleMatrixReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DeviceInfoReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DeviceCountDetails',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SLAPercentage',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SLAPercentageSummary',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SLAMatrixReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SeizeReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DistrictWiseVehicleCount',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SlaVehicleCountReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DayWiseDistanceTraveledReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/DeviceDetailsReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path: '#/SpeedViolationReport',
        loadChildren: () => import('./views/pages/reports/masterreport/masterreport.module').then(m => m.MasterReportModule)
      },
      {
        path:'devicestatus',
        loadChildren:() => import('./views/pages/deviceconfig/devicestatus/devicestatus.module').then(m => m.DevicestatusModule)
      },
      {
        path:'deviceinstallation',
        loadChildren:() => import('./views/pages/deviceconfig/deviceinstallation/deviceinstallation.module').then(m => m.DeviceinstallationModule)
      },
      {
        path:'installationdetail',
        loadChildren:() => import('./views/pages/deviceconfig/installationdetail/installationdetail.module').then(m => m.InstallationdetailModule)
      },
      {
        path:'Livestreaming',
        loadChildren:() => import('./views/pages/livestreaming/livestreaming/livestreaming.module').then(m => m.LivestreamingModule)
       },
       {
        path:'packingentry',
        loadChildren:() => import('./views/pages/packingentry/packingentry.module').then(m => m.PackingentryModule)
       },
       {
        path:'Returndevices',
        loadChildren:() => import('./views/pages/packingentry/returnform/returnform.module').then(m => m.ReturndeviceModule)
       },
       {
        path:'DeviceStatus',
        loadChildren:() => import('./views/pages/packingentry/devicesstatus/devicestatus.module').then(m => m.DevicestatusModule)
       },       
       {
        path:'geofence',
        loadChildren:() => import('./views/pages/geofence/geofence.module').then(m => m.GeofenceModule)
       },
       {
        path:'installdevicesummaryreport',
        loadChildren:() => import('./views/pages/reports/installed-device-summary-report/installed-device-summary-report.module').then(m => m.InstalledDeviceSummaryReportModule)
       },
       {
        path:'installdevicedetailreport',
        loadChildren:() => import('./views/pages/reports/installeddevicedetailreport/installeddevicedetailreport.module').then(m => m.InstalleddevicedetailreportModule)
       },
       {
        path:'recoverydevicesummaryreport',
        loadChildren:() => import('./views/pages/reports/recovereddevicesummaryreport/recovereddevicesummaryreport.module').then(m => m.RecovereddevicesummaryreportModule)
       },
       {
        path:'recoverydevicedetailreport',
        loadChildren:() => import('./views/pages/reports/recovereddevicedetailreport/recovereddevicedetailreport.module').then(m => m.RecovereddevicedetailreportModule)
       },
       
       {
        path:'barcodeentrylist',
        loadChildren:() => import('./views/pages/packingentry/barcodeentry/barcodeentry.module').then(m => m.BarcodeentryModule)
       },
       {
        path:'installationSummaryReport',
        loadChildren:() => import('./views/pages/reports/deviceinstallationstatusreport/deviceinstallationstatusreport.module').then(m => m.DeviceinstallationstatusreportModule)
       },
       {
        path:'plannedvsinstallationsummaryreport',
        loadChildren:() => import('./views/pages/reports/deviceinstallationstatusreport/deviceinstallationstatusreport.module').then(m => m.DeviceinstallationstatusreportModule)
       },
       
       {
        path:'dispatchsummaryreport',
        loadChildren:() => import('./views/pages/reports/dispatchsummaryreport/dispatchsummaryreport.module').then(m => m.DispatchsummaryModule)
       },
       {
        path:'imeisimmapping',
        loadChildren:() => import('./views/pages/packingentry/imeisimmapping/imeisimmapping.module').then(m => m.ImeisimmappingModule)
       },  
       {
        path:'simdeviceswapp',
        loadChildren:() => import('./views/pages/packingentry/simdeviceswapp/simdeviceswapp.module').then(m => m.SimdeviceswappModule)
       }, 
       {
        path:'devicelocationreset',
        loadChildren:() => import('./views/pages/master/devicelocationreset/devicelocationreset.module').then(m => m.DevicelocationresetModule)
       }, 
       {
        path:'districtdevicestatus',
        loadChildren:() => import('./views/pages/reports/districtinstallationstatus/districtinstallationstatus.module').then(m => m.DistrictinstallationstatusModule)
       },  
       {
        path:'gpsinstallationreport',
        loadChildren:() => import('./views/pages/reports/districtinstallationstatus/districtinstallationstatus.module').then(m => m.DistrictinstallationstatusModule)
       },   
       {
        path:'returnreport',
        loadChildren:() => import('./views/pages/reports/returnreport/returnreport.module').then(m => m.ReturnreportModule)
       },  
         
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
